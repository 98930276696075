<template>
  <v-app class="pt-15">
    <customize-header homeRouter="/assistantAccount/Home"
                      homeTitle="公众号助手"
                      :links="links"></customize-header>
    <router-view></router-view>
    <customize-suspendBtn></customize-suspendBtn>
    <customize-footer></customize-footer>
  </v-app>
</template>

<script>
export default {
  name: 'assistantAccount',
  data: () => ({
    links: [
      {
        title: "首页",
        router: "/assistantAccount/Home",
        children: []
      },
      {
        title: "CRM系统",
        router: "/assistantAccount/crm",
        children: []
      },
      {
        title: "营销应用",
        router: "/assistantAccount/marketing",
        children: []
      },
    ],
    
  }),

  mounted() {

  },
  methods: {

  }
};
</script>
<style lang="scss" scoped>


</style>